<script lang="ts">
  // @ts-ignore
  import Sortable from 'sortablejs';
  import { onMount } from 'svelte';
  import {createEventDispatcher} from 'svelte';
  import CurationListItem from './CurationListItem.svelte';
  import CurationSectionActions from './CurationSectionActions.svelte';
  import EditableTextArea from '../shared/EditableTextArea.svelte';
  import Icon from '../shared/Icon.svelte';
  import { toast } from '../shared/toastStore';

  export let editable: boolean = false;
  export let parentId: number = 0;
  export let parentSortable: Sortable;
  export let sectionContents: any = [];
  export let sectionCuratableId: number = 0;
  export let sectionDescription = '';
  export let sectionDuration: string = '00:00:00';
  export let sectionTitle = '';
  export let sectionId = 1;

  export let sectionDescriptionPlaceholder = 'Click to edit section description';
  export let sectionDragPlaceholder = 'Drag content here using the hand icon.';
  export let sectionDurationLabel = 'Duration';
  export let sectionTitlePlaceholder = 'Click to edit section name';

  export let sectionActionsMoveUpLabel = 'Move Section Up';
  export let sectionActionsMoveDownLabel = 'Move Section Down';
  export let sectionActionsDeleteLabel = 'Delete Section';

  export let sectionDeleteConfirmationText = 'Are you sure you want to delete this section, including the items within it?';
  export let sectionDeleteCompleteText = 'This section was deleted from your curation';

  let sectionContentsList: HTMLDivElement;

  let sectionSortable: any;
  let sectionLength = sectionContents ? sectionContents.length : 0;

  $: parentLength = parentSortable?.toArray().length;

  const dispatch = createEventDispatcher();

  onMount(async function () {
    sectionSortable = Sortable.create(sectionContentsList, {
      group: {
        name: 'sectionContentsList',
        put: true,
        pull: true
      },
      animation: 200,
      filter: '#addnew', // prevent Add New bar from being sorted
      handle: '.handle',
      // Element dragging ended
      onEnd: function (/**Event*/ evt: any) {
        let itemEl = evt.item.getAttribute('data-id');
        let fromCuration = evt.from.getAttribute('data-curatable-id');
        let toCuration = evt.to.getAttribute('data-curatable-id');
        if (toCuration === null) {
          // The above works when dragging things into a section or between sections
          // The parentId works when dragging into the main curation out of a section
          toCuration = parentId;
        }
        let newIndex = evt.newIndex; // element's new index within new parent

        handleSort(itemEl, newIndex, fromCuration, toCuration);
      },
      onRemove: function () {
        sectionLength = sectionSortable.toArray().length;
      },
      onAdd: function () {
        sectionLength = sectionSortable.toArray().length;
      }
    });
  });

  function handleSort(itemEl: number, newIndex: number, fromCuration: number, toCuration: number) {
    fetch(`/curations/${fromCuration}/curated_items/${itemEl}`, {
      method: 'PATCH',
      credentials: 'same-origin',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        // @ts-ignore
        'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      },
      body: JSON.stringify({
        position: newIndex,
        to_curation: toCuration
      })
    });
  }

  function handleMove(increment:1 | -1){
    let order = parentSortable.toArray();
    let currentItem = sectionId.toString();
    let sorted = moveItem(order.indexOf(currentItem), order.indexOf(currentItem) + increment, order);
    let newIndex = currentIndex + increment;

    parentSortable.sort(sorted, true);
    handleSort(sectionId, newIndex, parentId, parentId);
    dispatch('move', { id: sectionId, curatable: sectionCuratableId });
  }

  function handleRemovingSection(e: any) {
    const response = confirm(sectionDeleteConfirmationText);

    if (response == true) {
      fetch(`/curations/${parentId}/curated_items/${sectionId}`, {
        method: "DELETE",
        credentials: "same-origin",
        // @ts-ignore
        headers: {
          "Content-Type": "application/json",
          // @ts-ignore
          "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        },
        body: JSON.stringify({
          curatable_id: sectionCuratableId,
        })
      });

      const item = document.querySelector(`[data-id="${sectionId}"]`);
      if (item) {
        item.remove();
        toast.add(sectionTitle, { title: sectionDeleteCompleteText });
      }
    } else {
      return;
    }
  }

  $: currentIndex = getCurrentIndex(parentSortable);

  function getCurrentIndex(parent:any){
    if(!parent) return;
    return parent.toArray().indexOf(sectionId.toString());
  }

  function moveItem(from: number, to: number, data: any) {
    // This handles the appearance of moving the sections around on the page
    var f = data.splice(from, 1)[0];
    data.splice(to, 0, f);

    return data;
  }
</script>

<div
  data-id={sectionId}
  data-level={1}
  class="border border-black p-4 rounded flex flex-col gap-3 bg-white"
>
  <div class="mb-4 pb-2 border-b border-neutral-200">
    <div class="w-full border-b mb-2 flex justify-between">
      <p class="text-lg font-bold max-w-lg">
        <EditableTextArea action="/curations/{sectionCuratableId}"
          contents={sectionTitle} contentStyle="min-w-96" editable={editable}
          model="curation" placeholder={sectionTitlePlaceholder} property="name" />
      </p>

      <div class="flex gap-2 items-center">
        <p class="text-xs italic mr-2">{sectionDurationLabel}: {sectionDuration}</p>
        {#if editable}
          <div class="w-7 h-7 bg-white shadow-md p-1.5 hover:p-1 transition-all rounded handle">
            <Icon icon="handle" />
          </div>
          <CurationSectionActions
            isFirst={currentIndex === 0}
            isLast={parentLength === currentIndex + 1}
            on:moveUp={()=>handleMove(-1)}
            on:moveDown={()=>handleMove(1)}
            on:delete={handleRemovingSection}
            id={sectionId}
            curatable={sectionCuratableId}
            moveUpLabel={sectionActionsMoveUpLabel}
            moveDownLabel={sectionActionsMoveDownLabel}
            deleteLabel={sectionActionsDeleteLabel}
          />
        {/if}
      </div>
    </div>

    <p class="text-sm text-neutral-500 max-w-lg italic">
      <EditableTextArea action="/curations/{sectionCuratableId}"
        contents={sectionDescription} contentStyle="min-w-96"
        editable={editable} model="curation"
        placeholder={sectionDescriptionPlaceholder} property="description" />
    </p>
  </div>
  <div
    bind:this={sectionContentsList}
    data-id={sectionId}
    data-curatable-id={sectionCuratableId}
    class:empty={sectionLength === 0}
    class="flex flex-col gap-3 py-2 w-full bg-white"
    >
    {#if sectionContents && sectionContents.length}
      {#each sectionContents as item, i}
        <CurationListItem
          content_count={item.content_count}
          duration={item.duration}
          editable={editable}
          href={item.href}
          learning_objectives={item.learning_objectives}
          parent={item.curation_id}
          progress={item.progress}
          subhead={item.expert_name}
          subeyebrow={item.expert_title}
          thumbnail_url={item.thumbnail_url}
          title={item.title}
          type_overlay={item.type_overlay}
          updateable={item.updateable}
          id={item.id}
        />
      {/each}
    {/if}

    {#if sectionLength === 0}
      <div class="flex items-center justify-center">
        <p class="text-neutral-500 text-sm italic">{sectionDragPlaceholder}</p>
      </div>
    {/if}
  </div>
</div>


<style>
  .empty {
    min-height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 2px dashed #dadada;
  }
</style>
