<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  // @ts-ignore
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  // @ts-ignore
  import { Button } from '$lib/components/ui/button';
  import { MoreHorizontal } from 'lucide-svelte';

  const dispatch = createEventDispatcher();

  export let id: number;
  export let isFirst:boolean;
  export let isLast: boolean;
  export let curatable: number;

  export let moveUpLabel = 'Move Section Up';
  export let moveDownLabel = 'Move Section Down';
  export let deleteLabel = 'Delete Section';

  function handleMoveUp() {
    dispatch('moveUp', {
      id: id,
      curatable: curatable
    });
  }
  function handleMoveDown() {
    dispatch('moveDown', {
      id: id,
      curatable: curatable
    });
  }

  function handleDelete() {
    dispatch('delete', {
      id: id,
      curatable: curatable
    });
  }
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Button variant="ghost" builders={[builder]} size="icon" class="relative w-8 h-8 p-0">
      <span class="sr-only">Open menu</span>
      <MoreHorizontal class="w-4 h-4" />
    </Button>
  </DropdownMenu.Trigger>
  <DropdownMenu.Content>
    <DropdownMenu.Group>
      <DropdownMenu.Item class="{isFirst ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveUp}>{moveUpLabel}</DropdownMenu.Item>
      <DropdownMenu.Item class="{isLast ? 'pointer-events-none opacity-50' : ''}" on:click={handleMoveDown}>{moveDownLabel}</DropdownMenu.Item>
    </DropdownMenu.Group>
    <DropdownMenu.Separator />
    <DropdownMenu.Item on:click={handleDelete}>{deleteLabel}</DropdownMenu.Item>
  </DropdownMenu.Content>
</DropdownMenu.Root>
